/* add onload event to the window */
window.onload = function(){
	/* declare some global variables that will be used through the script */
	var menuDown = false;
	var searchDown = false;
	var rotationDegrees = 0;
	var settingsDown = false;
	var nightmode = false;
	var font_size = 1;
	var language = "en";
	/* hide or show navbar and settings menu based on scroll position */
	var prevScrollpos = window.pageYOffset;
	window.onscroll = function(){
		var currentScrollpos = window.pageYOffset;
		if(prevScrollpos > currentScrollpos || currentScrollpos < 100){
			document.getElementById("masthead").style.top = 0;
		} else {
			document.getElementById("masthead").style.top = "-150px";
			if(settingsDown){
				document.getElementById("settings").style.opacity = 0;
				setTimeout(function(){ document.getElementById("settings").style.display = "none"; }, 400);
				settingsDown = false;
			}
		}
		prevScrollpos = currentScrollpos;
	}
	/* get the navbar height */
	var mastheadHeight = document.getElementById("masthead").offsetHeight;
	/* move the search container up out of sight */
	document.getElementById("search").style.top = "-" + (mastheadHeight+15) + "px";
	/* make the search container the same height as the navbar */
	document.getElementById("search").style.height = mastheadHeight + "px";
	/* add onclick event to the hamburger icon */
	document.getElementById("menu_hamburger_icon").onclick = function(){
		/* animate the hamburger icon */
		this.classList.toggle('active');
		/* check if menu is visible */
		if(menuDown){
			/* menu is not visible, remove blur, set opacity back to 0 in steps, set display none for the menu and enable scrolling */
			var setopacity = 1;
			var setblur = 11;
			/* enable page scrolling */
			document.getElementsByTagName("html")[0].style.overflow = "auto";
			document.getElementsByTagName("body")[0].style.overflow = "auto";
			/* setInterval to set opacity to 0 in steps */
			var fadeout = setInterval(function(){
				setopacity -= .1;
				setblur -= 1;
				document.getElementById("menu").style.opacity = setopacity;
				document.getElementById("main").style.filter = "blur(" + setblur + "px)";
				if(setopacity <= 0){
					/* when reaching 0 opacity stop the interval function */
					clearInterval(fadeout);
					document.getElementById("menu").style.display = "none";
					menuDown = false;
				}
			}, 15);
		} else {
			/* menu is visible, add blur, set opacity to 1 in steps, set display block for the menu and disable scrolling */
			document.getElementById("menu").style.display = "block";
			var setopacity = 0;
			var setblur = 0;
			/* disable page scrolling */
			document.getElementsByTagName("html")[0].style.overflow = "hidden";
			document.getElementsByTagName("body")[0].style.overflow = "hidden";
			/* setInterval to set opacity to 1 in steps */
			var fadein = setInterval(function(){
				setopacity += .1;
				setblur += 1;
				document.getElementById("menu").style.opacity = setopacity;
				document.getElementById("main").style.filter = "blur(" + setblur + "px)";
				/* when reaching 1 opacity stop the interval function */
				if(setopacity >= 1){
					clearInterval(fadein);
					menuDown = true;
				}
			}, 15);
		}
	}
	/* add onclick event to search icon */
	document.getElementById("menu_search_icon").onclick = function(){
		/* move down the search container */
		document.getElementById("search").style.top = "0";
		$("#search_overlay").fadeIn();
		searchDown = true;
	}
	/* set click event to the whole document */
	document.addEventListener("click", function(e){
		e = e || window.event;
		var target = e.target || e.srcElement;
		/* if the search is visible and user clicks on the document except on search elements move up the search container */
		if(searchDown && target.id != "menu_search_icon" && target.id != "search" && target.id != "search_button" && target.id != "search_input"){
			document.getElementById("search").style.top = "-" + (mastheadHeight+15) + "px";
			$("#search_overlay").fadeOut();
			searchDown = false;
		}
		/* if the settings menu is visible and user clicks on the document except on settings menu elements fadeout the settings menu */
		if(settingsDown && target.id != "settings" && target.id != "menu_cog_icon" && target.id != "night_mode_button_container" && target.id != "night_mode_span" && target.id != "night_mode_button" && target.id != "font_size_button_container" && target.id != "font_size_label" && target.id != "font-size_button" && target.id != "font_size_svg" && target.id != "svg_rect" && target.id != "font_size_icon" && target.id != "language_button_container" && target.id != "language_button_span" && target.id != "language_button" && target.id != "button-1" && target.id != "language_button_input" && target.id != "language_button_knob" && target.id != "language_button_layer"){
			document.getElementById("settings").style.opacity = 0;
			setTimeout(function(){ document.getElementById("settings").style.display = "none"; }, 400);
			settingsDown = false;
		}
	});
	/* add onmouseover event to cog icon */
	document.getElementById("menu_cog_icon").onmouseover = function(){
		/* calculate the rotation and rotate the cog icon */
		rotationDegrees += 360;
		this.style.transform = "rotate(" + rotationDegrees + "deg)";
	}
	/* add onclick event to cog icon */
	document.getElementById("menu_cog_icon").onclick = function(e){
		e.preventDefault();
		/* calculate the rotation and rotate the cog icon */
		rotationDegrees += 360;
		this.style.transform = "rotate(" + rotationDegrees + "deg)";
		/* check if settings menu is visible or not and show/hide the settings menu accordingly */
		if(settingsDown){
			document.getElementById("settings").style.opacity = 0;
			setTimeout(function(){ document.getElementById("settings").style.display = "none"; }, 400);
			settingsDown = false;
		} else {
			document.getElementById("settings").style.display = "block";
			setTimeout(function(){ document.getElementById("settings").style.opacity = 1; }, 50);
			settingsDown = true;
		}
	}
	/* night mode button */
	$('.mode').click(function(){
		/* check if page is in nightmode or daymode and switch accordingly */
		if(nightmode){
			/* remove classes for nightmode */
			$("html").removeClass("primary_nightmode");
			$("body").removeClass("primary_nightmode");
			$("#masthead .container").removeClass("primary_nightmode");
			$("#settings").removeClass("primary_nightmode");
			$("#main .exercise-layout").removeClass("primary_nightmode");
			$("#main .card-content").removeClass("primary_nightmode");
			$("#main #post-content").removeClass("primary_nightmode");
			$("#main .sidebar").removeClass("primary_nightmode");
			$("#main .subscribe-jumbotron").removeClass("primary_nightmode");
			$("#footer").removeClass("primary_nightmode");
			nightmode = false;
		} else {
			/* add classes for nightmode */
			$("html").addClass("primary_nightmode");
			$("body").addClass("primary_nightmode");
			$("#masthead .container").addClass("primary_nightmode");
			$("#settings").addClass("primary_nightmode");
			$("#main .exercise-layout").addClass("primary_nightmode");
			$("#main .card-content").addClass("primary_nightmode");
			$("#main #post-content").addClass("primary_nightmode");
			$("#main .sidebar").addClass("primary_nightmode");
			$("#main .subscribe-jumbotron").addClass("primary_nightmode");
			$("#footer").addClass("primary_nightmode");
			nightmode = true;
		}
		/* toggle classes for animation for nightmode button */
		$(this).toggleClass("off");
		var toggl = $(this);
		toggl.addClass('scaling');
		setTimeout(function() {
			toggl.removeClass('scaling');
		}, 520);
	});
	/* font size button on mouse over add animation class */
	document.getElementById("font-size_button").onmouseover = function(){
		$("#svg_rect").addClass("run_animation");
	}
	/* font size button on mouse out remove animation class */
	document.getElementById("font-size_button").onmouseout = function(){
		$("#svg_rect").removeClass("run_animation");
	}
	/* font size button click */
	document.getElementById("font-size_button").onclick = function(){
		/* add animation class */
		$("#svg_rect").addClass("run_animation");
		/* check which font size is being used and act accordingly */
		if(font_size == 1){
			document.getElementById("font_size_icon").style.fontSize = "18px";
			document.getElementById("font_size_icon").style.top = "0px";
			document.getElementById("font_size_icon").style.left = "8px";
			document.getElementById("font_size_label").style.top = "1px";
			document.getElementById("fontsize_stylesheet").setAttribute("href", "../assets/css/fontsize2.css");
			font_size = 2;
		} else if(font_size == 2){
			document.getElementById("font_size_icon").style.fontSize = "20px";
			document.getElementById("font_size_icon").style.top = "0px";
			document.getElementById("font_size_icon").style.left = "8px";
			document.getElementById("font_size_label").style.top = "0px";
			document.getElementById("fontsize_stylesheet").setAttribute("href", "../assets/css/fontsize3.css");
			font_size = 3;
		} else if(font_size == 3){
			document.getElementById("font_size_icon").style.fontSize = "22px";
			document.getElementById("font_size_icon").style.top = "-2px";
			document.getElementById("font_size_icon").style.left = "7px";
			document.getElementById("font_size_label").style.top = "-3px";
			document.getElementById("fontsize_stylesheet").setAttribute("href", "../assets/css/fontsize4.css");
			font_size = 4;
		} else if(font_size == 4){
			document.getElementById("font_size_icon").style.fontSize = "16px";
			document.getElementById("font_size_icon").style.top = "2px";
			document.getElementById("font_size_icon").style.left = "9px";
			document.getElementById("font_size_label").style.top = "4px";
			document.getElementById("fontsize_stylesheet").setAttribute("href", "../assets/css/fontsize1.css");
			font_size = 1;
		}
	}
	/* language button click */
	document.getElementById("button-1").onclick = function(){
		/* check which language is being used and toggle hide class accordingly */
		if(language == "en"){
			$(".english_text").addClass("hide_language");
			$(".spanish_text").removeClass("hide_language");
			language = "es";
		} else {
			$(".english_text").removeClass("hide_language");
			$(".spanish_text").addClass("hide_language");
			language = "en";
		}
	}
}